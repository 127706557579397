import React, { useState, useRef } from 'react';
import SectionTitle from '../shared/section-titles';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Slider from 'react-slick';
import constants from '../../constants/home';
import Popup from '../shared/popup';

import arrowRightWhite from '../../images/index/right-arrow-white.svg'
import arrowLeftWhite from '../../images/index/left-arrow-white.svg'

const BackgroundWrapper = styled.div`
  background-image: url(${props => props.bgImg});
  background-size: 100% 80%;
  /* background-size: cover; */
  background-position: center;
  background-repeat: no-repeat; 
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    min-height: 1200px;
  }

  @media screen and (min-width: 1200px) {
    min-height: 1200px;
  }

  @media screen and (min-width: 1800px) {
    min-height: 1500px;
  }

  @media screen and (max-width: 991px){
    background-size: cover;
  }

  @media screen and (max-width: 576px) {
    min-height: 700px;
  }
`;


const HelpCardsSection = () => {
  const data = useStaticQuery(graphql`
    query HelpImgBg{
      bgImg: file(relativePath: {eq: "home/help-cards-bg.png"}) {
        childImageSharp {
          fluid (maxHeight: 1500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);


  return (
    <section id='help-card-section' className=''>
      <BackgroundWrapper bgImg={data.bgImg.childImageSharp.fluid.src}>
        <div className='container'>
          <SectionTitle>
            <h2 className='text-white'>Безплатен обяд има:<br />при нас, и то към всеки пакет</h2>
            <hr className='center white' />
          </SectionTitle>

          <div className='reasons-slider mx-3 mx-md-0'>
            <Slider
              {...settings}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              {constants.helpCards.map((cardContent, index) => (
                <div key={index} className='d-flex d-lg-inline-block justify-content-center'>
                  <HelpCard
                    {...cardContent}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </BackgroundWrapper>
    </section>
  )
}

const HelpCardWrapper = styled.div`
  background-color: transparent;
  border-radius: 17px;
  padding: 0.5rem;
  margin: 0.5rem;
  width: 350px;
  display: flex;
  justify-items: center;
  &:hover {
    background-color: rgba(21, 31, 65, 0.2);
  }
`;

const HelpCard = ({ title, text, overflow, icon }) => {
  const [show, setShow] = useState(false);
  let ref = useRef(null);

  return (
    <>
      <HelpCardWrapper onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        <div ref={ref}>
          <img src={icon} className='img-fluid' alt={'Икона за ' + title} />
          <div className='pl-3'>
            <h4 className='text-white'>{title}</h4>
            <p className='text-white'>{text} {(overflow && <span className='icon info'></span>)}</p>
          </div>
        </div>
      </HelpCardWrapper>
      {overflow && (
        <>
          <Popup
            showTelephonePopover={show}
            target={ref}
            placement='top'>
            <div className='popup bg-white p-4' style={{ maxWidth: '350px' }}>
              <p>{overflow}</p>
            </div>
          </Popup>
        </>
      )}
    </>
  );
};

const settings = {
  rows: 2,
  slidesPerRow: 3,
  infinite: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        rows: 2,
        slidesPerRow: 2,
      }
    },
    {
      breakpoint: 991,
      settings: {
        rows: 2,
        slidesPerRow: 1,
      }
    },
    {
      breakpoint: 576,
      settings: {
        rows: 1,
        slidesPerRow: 1,
      }
    },
  ]
}

const NextArrow = (props) => (
  <img
    {...props}
    src={arrowRightWhite}
    alt="Arrow Right"
    className={props.className + ' img-fluid mr-1'}
    style={{ ...props.style, display: 'block' }}
    onClick={props.onClick}
  />
);

const PrevArrow = (props) => (
  <img
    {...props}
    src={arrowLeftWhite}
    alt="Arrow Left"
    className={props.className + ' img-fluid ml-1'}
    style={{ ...props.style, display: 'block' }}
    onClick={props.onClick}
  />
);

export default HelpCardsSection;